import { createContext } from 'react';

import { ERoles } from '../../../../1_shared/config/enums/ERoles';
import { ISpecialistByIdData } from '../../../../1_shared/config/interfaces/ISpecialistByIdData';
import { ISpecialistData } from '../../../../1_shared/config/interfaces/ISpecialistData';
import { IClientPriviewOutput } from '../../../api/interfaces/IClientPriviewOutput';

export const AuthContext = createContext<{
  user: (IClientPriviewOutput & ISpecialistData & ISpecialistByIdData) | null;
  role: ERoles;
  logout?: () => Promise<void>;
  login?: (role: ERoles) => void;
  setSpecUser?: (spec: ISpecialistData) => void;
  clearUser?: () => void;
}>({
  role: ERoles.Unauthorized,
  user: null,
});
