import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { CloseCircleFilled } from '@ant-design/icons';
import { Checkbox } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';

import { getDictionaries } from '1_shared/api/dictionary';
import useSWRWithCache from '1_shared/api/lib/useSWRWithCache';
import { ISpecialistListFilters } from '1_shared/config/interfaces';
import { IOption } from '1_shared/config/interfaces/IOption';
import {
  dayTypeFilterOptions,
  experienceFilterOptions,
  sexFilterOptions,
} from '1_shared/constants/filterEnums';
import { DatePicker, Input, Select } from '1_shared/ui';

import { SessionType } from '../../../1_shared/config/interfaces/ISpecialistListFilters';
import changeParameterInRoute from '../../../1_shared/helpers/changeParameterInRoute';
import separateParams from '../../../1_shared/helpers/separateParams';
import { Label } from '../../../1_shared/ui/Label';
import { ESortType } from '../../../4_widgets/SpecialistFilters/ui/SpecialistFilters';
import { priceOptions, timeOptions } from '../options/options';

import { IFilterFormProps } from './interfaces/IFilterFormProps';

import styles from './FilterForm.module.scss';

const FiltersForm = ({ handleFieldChange }: IFilterFormProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { control, watch, getValues, setValue, reset } =
    useFormContext<ISpecialistListFilters>();

  watch(); // moneyFilter.minMoneyRate

  const { data: workWithOptions } = useSWRWithCache<IOption<string>[]>(
    '/spec/work-withs',
    getDictionaries,
  );
  const { data: keyThemesOptions } = useSWRWithCache<IOption<string>[]>(
    '/spec/key-themes',
    getDictionaries,
  );
  const { data: specialitiesOptions } = useSWRWithCache<IOption<string>[]>(
    '/spec/specialities',
    getDictionaries,
  );
  const { data: dictionaryMethods } = useSWRWithCache<IOption[]>(
    '/spec/work-methods',
    getDictionaries,
  );

  const addParameterFilter = (fieldName: string, value?: string) => {
    changeParameterInRoute([fieldName, value], location, navigate);
  };

  const handleTimeChange = (value: { start: string; end: string }) => {
    setValue('slotsFilter.localTimeFrom', value.start || '');
    setValue('slotsFilter.localTimeTo', value.end || '');
    handleFieldChange();
  };

  const handlePriceChange = (value: {
    min: number | null;
    max: number | null;
  }) => {
    setValue('moneyFilter.minMoneyRate', value.min);
    setValue('moneyFilter.maxMoneyRate', value.max);
    handleFieldChange();
  };

  const handleOnlySupervisionChange = (value: boolean) => {
    setValue('sessionType', value ? SessionType.SUPERVISION : null);
    handleFieldChange();
  };

  const parsedValuePrice = (value: string) => (value ? JSON.parse(value) : {});
  const selectedOptionPrice = (value: string) =>
    priceOptions.find(
      option =>
        option.value.min === parsedValuePrice(value)?.min &&
        option.value.max === parsedValuePrice(value)?.max,
    );
  const selectedOptionTime = () =>
    timeOptions.find(
      option =>
        option.value.start === getValues('slotsFilter.localTimeFrom') &&
        option.value.end === getValues('slotsFilter.localTimeTo'),
    );

  useEffect(() => {
    reset({
      ...getValues(),
      ...separateParams(location.search),
      sortRequest: {
        ...getValues().sortRequest,
        sortType:
          getValues().sortRequest.sortType ?? ESortType.BY_NEAREST_SESSION,
      },
    });
    // TODO: REFACTOR - delete handleFieldChange();
    handleFieldChange();
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.filterRow}>
        <Controller
          name="workWith"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder="Все темы"
              name="workWith"
              label="с кем работают"
              className={cn(styles.workWith, styles.select, {
                [styles.selected]: value,
              })}
              value={
                value &&
                workWithOptions.find((option: any) => option.value === value)
              }
              options={workWithOptions}
              suffixIcon={
                value && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
            />
          )}
        />

        <Controller
          name="keyThemes"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              placeholder="Ключевые темы"
              name="keyThemes"
              label="ключевые темы"
              optionFilterProp="label"
              maxTagCount="responsive"
              className={cn(styles.workWith, styles.keyThemes, {
                [styles.selected]: value,
              })}
              options={keyThemesOptions}
              suffixIcon={
                value &&
                value.length > 0 && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
              value={value}
              mode="multiple"
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
            />
          )}
        />

        <Controller
          name="price"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              placeholder="Любой"
              name="price"
              label="диапазон цен"
              value={
                value && selectedOptionPrice(value)
                  ? JSON.stringify(selectedOptionPrice(value)?.value)
                  : null
              }
              options={priceOptions.map(option => ({
                label: option.label,
                value: JSON.stringify(option.value),
              }))}
              className={cn(styles.workWith, {
                [styles.selected]: selectedOptionPrice(value),
              })}
              onChange={value => {
                onChange(value);
                handlePriceChange(JSON.parse(value));
              }}
              suffixIcon={
                !!parsedValuePrice(value).min ||
                !!parsedValuePrice(value).max ? (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handlePriceChange({ min: null, max: null });
                    }}
                  />
                ) : null
              }
            />
          )}
        />
        <Controller
          name="experience"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder="Не важен"
              name="experience"
              value={value}
              label="опыт"
              options={experienceFilterOptions}
              className={cn(styles.workWith, {
                [styles.selected]: value,
              })}
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
              suffixIcon={
                value && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
            />
          )}
        />
        <Controller
          name="sex"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              placeholder="Не важен"
              name="sex"
              label="пол"
              value={value}
              options={sexFilterOptions}
              className={cn(styles.workWith, {
                [styles.selected]: value,
              })}
              suffixIcon={
                value && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
            />
          )}
        />
      </div>
      <div className={styles.filterRow}>
        <Controller
          name="specialties"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder="Любые"
              name="specialties"
              label="специальность"
              value={value}
              className={cn(styles.workWith, {
                [styles.selected]: value,
              })}
              options={specialitiesOptions}
              suffixIcon={
                value && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                      addParameterFilter('specialties');
                    }}
                  />
                )
              }
              onChange={(value: string) => {
                onChange(value || null);
                handleFieldChange();
                addParameterFilter('specialties', value);
              }}
            />
          )}
        />
        <Controller
          name="workMethod"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="Любые"
              name="workMethod"
              label="методы работы"
              value={value}
              className={cn(styles.workWith, {
                [styles.selected]: value,
              })}
              options={dictionaryMethods}
              suffixIcon={
                value && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                      addParameterFilter('workMethod');
                    }}
                  />
                )
              }
              onChange={(value: string) => {
                onChange(value || null);
                handleFieldChange();
                addParameterFilter('workMethod', value);
              }}
            />
          )}
        />
        <Controller
          name="sessionType"
          control={control}
          render={({ field: { value } }) => (
            <div className={styles.superVisionSwitch}>
              <Checkbox
                onChange={checked => {
                  handleOnlySupervisionChange(checked.target.checked);
                }}
                value={value === SessionType.SUPERVISION}
                checked={value === SessionType.SUPERVISION}
              >
                <Label name="sessionType" label="Только супервизии" />
              </Checkbox>
            </div>
          )}
        />
      </div>
      <div className={styles.filterRow}>
        {/* <div className={styles.experienceDayTypeContainer}> */}
        <Controller
          name="slotsFilter.dayType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder="Не важен"
              name="dayType"
              label="тип дня"
              value={value}
              options={dayTypeFilterOptions}
              className={cn(styles.workWith, {
                [styles.selected]: value,
              })}
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
              suffixIcon={
                value && (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      onChange(null);
                      handleFieldChange();
                    }}
                  />
                )
              }
            />
          )}
        />
        {/* </div> */}
        <Controller
          name="slotsFilter.date"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              name="date"
              showNow={false}
              minDate={dayjs().add(1, 'day')}
              value={value ? dayjs(value) : null}
              label="дата"
              onChange={value => {
                onChange(value || null);
                handleFieldChange();
              }}
              onKeyDown={e => {
                if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                  e.preventDefault();
                }
              }}
              className={cn(styles.workWith, {
                [styles.selected]: value,
              })}
            />
          )}
        />

        <Controller
          name="slotsFilter.localTime"
          control={control}
          render={() => (
            <Select
              placeholder="Любое время"
              name="localTime"
              label="время"
              value={selectedOptionTime() || null}
              options={timeOptions.map(option => ({
                label: option.label,
                value: JSON.stringify(option.value),
              }))}
              className={cn(styles.workWith, {
                [styles.selected]: selectedOptionTime(),
              })}
              onChange={option => {
                handleTimeChange(JSON.parse(option));
              }}
              suffixIcon={
                getValues('slotsFilter.localTimeFrom') ||
                getValues('slotsFilter.localTimeTo') ? (
                  <CloseCircleFilled
                    className={styles.icon}
                    onClick={() => {
                      handleTimeChange({ start: '', end: '' });
                      handleFieldChange();
                    }}
                  />
                ) : null
              }
            />
          )}
        />
        <Controller
          name="surname"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              placeholder="Иванов"
              type="string"
              name="surname"
              value={value}
              label="Фамилия специалиста"
              className={cn(styles.workWith, {
                [styles.selected]: value,
              })}
              onInput={e => {
                onChange((e.target as HTMLInputElement)?.value || null);
                handleFieldChange();
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default FiltersForm;
