import { ReactElement } from 'react';

import {
  About,
  DirectionsGroup,
  DirectionsSingle,
  Experts,
  Faq,
  Feedback,
  Footer,
  HeroMain,
  MeetingsGroup,
  MeetingsSingle,
  Problem,
  Programs,
  PsychologistsNear,
  Specialists,
  Statistics,
  Survey,
} from '1_shared/ui'; // Забираем нужную нам функцию из пакета, функции именуются - компонентами
import Page from '1_shared/ui/Page/ui/Page';
import { Header, Video } from '4_widgets';

import { NewYear2024 } from '../../../1_shared/constants/promoCampaigns/NewYear2024';
import SurveyDiscount from '../../../1_shared/ui/SurveyReact/ui/SurveyDiscount';
import ThreePromoBanners from '../../../1_shared/ui/ThreePromoBanners/ui/ThreePromoBanners';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';

const MainPage = (): ReactElement => {
  const { user } = useAuthContext();
  return (
    <Page withFloatButton>
      <Header />
      <HeroMain />
      <Statistics />
      <Problem />
      <ThreePromoBanners
        title="Специальное предложение"
        items={NewYear2024}
        // nameOfPromo="promoNewYear"
        isAuth={!!user?.userId}
      />
      <SurveyDiscount />
      <Video />
      <Survey />
      <DirectionsSingle />
      <Specialists />
      <MeetingsSingle />
      <DirectionsGroup />
      <Programs />
      <MeetingsGroup />
      <PsychologistsNear />
      <About />
      <Experts />
      <Feedback />
      {/* <Progs4Psychologists /> */}
      <Faq />
      <Footer />
    </Page>
  );
};

export default MainPage;
