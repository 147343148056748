import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import useSWRMutation from 'swr/mutation';

import { ERoles } from '1_shared/config/enums/ERoles';

import { ELoginStatus } from '../../../1_shared/config/enums/ELoginStatus';
import { EMessageType } from '../../../1_shared/constants/EMessageType';
import { UserRoutes } from '../../../1_shared/constants/userRoutes';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import useMessages from '../../../app/module/lib/hooks/useMessages';
import { isPhoneField } from '../lib/checkPhone';

import { loginByCode, loginByPassword, sendCodeReq } from './api/auth.service';
import { IArgLogin } from './intreface/login/IArgLogin';
import { IAuthByPassword } from './intreface/login/IAuthByPassword';
// eslint-disable-next-line import/no-cycle
import { IHookLoginOutput } from './intreface/login/IHookLoginOutput';
import { ILoginWithCode } from './intreface/login/ILoginWithCode';
import { IRequestCode } from './intreface/login/IRequestCode';
import { ISendAuthorizeData } from './useSubmit';

const useLogin = ({
  status,
  setLoginStatus,
  onClose,
}: IArgLogin): IHookLoginOutput => {
  const { login: loginAuth } = useAuthContext();
  const { messageApi } = useMessages();
  const location = useLocation();
  const { state } = location as {
    state: { pathname?: string; state: unknown };
  };
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { trigger: sendCodeTr } = useSWRMutation(
    '/aim/sessions/request-code',
    sendCodeReq,
  );

  const {
    // data: dataUser,
    trigger: loginByCodeTr,
  } = useSWRMutation('/aim/api/v1/auth/otp', loginByCode);

  const { trigger: authByEmailTr } = useSWRMutation(
    '/aim/auth',
    loginByPassword,
  );

  /* Запрос на получение OTP-кода для авторизации по номеру */
  const sendCode = async (data: Partial<ISendAuthorizeData>) => {
    try {
      const tempData: IRequestCode = {
        contactInfo: data.login,
        contactType: isPhoneField(data.login)
          ? EMessageType.PHONE
          : EMessageType.EMAIL,
        // role: (dataUser?.role ?? data.role) as ERoles,
      };
      setLoginStatus(ELoginStatus.CodeSended);
      await sendCodeTr(tempData);
      setLoginStatus(ELoginStatus.CodeSend);
    } catch (e) {
      if ((e as any).response?.status === 404) {
        setLoginStatus(ELoginStatus.NotFound);
      } else {
        setLoginStatus(ELoginStatus.None);
      }
    }
  };

  /* Авторизация по номеру телефона с полученным OTP-кодом */
  const sendLoginWithCode = async (data: Partial<ISendAuthorizeData>) => {
    const tempData: ILoginWithCode = {
      contactInfo: data.login,
      contactType: isPhoneField(data.login)
        ? EMessageType.PHONE
        : EMessageType.EMAIL,
      code: String(data.code),
    };
    const userData = await loginByCodeTr(tempData);
    localStorage.setItem('role', userData?.role as ERoles);
    return userData?.role;
  };

  /* Метод для авторизации по номеру телефона (запрос OTP + авторизация если OTP введен пользователем) */
  const phoneLogin = async (
    data: Partial<ISendAuthorizeData>,
    needRedirect = true,
    customRoute = null,
    // eslint-disable-next-line consistent-return
  ) => {
    if (status === ELoginStatus.CodeSend) {
      const userRole = await sendLoginWithCode(data);
      const role = (localStorage.getItem('role') as ERoles) || userRole;
      setLoginStatus(ELoginStatus.CodeVerified);
      if (loginAuth) {
        await loginAuth(role);
        if (needRedirect) {
          if (customRoute) return navigate(customRoute, { state });
          navigate(UserRoutes?.[role], {
            state: state?.state,
          });
        } else if (onClose) onClose(null);
      }
    } else {
      await sendCode(data);
    }
  };

  /* метод для изменения состояния формы. авторизация по e-mail осуществляется с помощью пароля */
  const authByEmail = (data: Partial<ISendAuthorizeData>) => {
    if (data.login) {
      setLoginStatus(ELoginStatus.NeedPassword);
    }
  };

  /* Авторизация по эмейлу */
  const authByPassword = async (
    data: Partial<ISendAuthorizeData>,
    needRedirect = true,
    customRoute = null,
    // eslint-disable-next-line consistent-return
  ) => {
    const tempData: IAuthByPassword = {
      email: data.login,
      password: String(data.password),
    };
    try {
      const response: any = await authByEmailTr(tempData);
      const role = (response?.role as ERoles) || ERoles.Unauthorized;
      if (role) {
        localStorage.setItem('role', role);
      }
      if (loginAuth) {
        loginAuth(role);
        if (needRedirect && role) {
          if (customRoute) return navigate(customRoute, { state });
          navigate(UserRoutes[role], {
            state: state?.state,
          });
        } else if (onClose) onClose(null);
      }
    } catch (e) {
      if ((e as AxiosError).response?.status === 404) {
        setLoginStatus(ELoginStatus.NotFound);
      }
      setErrorMessage(((e as AxiosError).response?.data as any)?.message);
    }
  };

  /* метод для осуществеления авторизации по e-mail адресу */
  const emailLogin = async (
    data: Partial<ISendAuthorizeData>,
    needRedirect?: boolean,
    customRoute = null,
  ) => {
    if (status === ELoginStatus.NeedPassword) {
      await authByPassword(data, needRedirect, customRoute);
    } else {
      authByEmail(data);
    }
  };

  /* метод для авторизации эмейл/телефон */
  const login = async (
    data: Partial<ISendAuthorizeData>,
    needRedirect?: boolean,
    customRoute?: any,
  ) => {
    if (isPhoneField(data.login)) {
      await phoneLogin(data, needRedirect, customRoute);
    } else {
      await emailLogin(data, needRedirect, customRoute);
    }
  };

  useEffect(() => {
    if (state?.pathname) {
      messageApi?.info({
        content:
          'Время пользовательской сессии истекло, пройдите повторно авторизацию',
        duration: 2,
      });
    }
  }, []);

  return {
    login,
    errorTimerMessage: errorMessage,
    setErrorTimerMessage: setErrorMessage,
  };
};

export default useLogin;
