import { useLocation, useNavigate } from 'react-router-dom';

import Close from '1_shared/assets/pictures/closeGray.svg';
import { LoginForm } from '4_widgets';

import { RoutePath } from '../../../1_shared/config/routes';

import styles from './LoginPage.module.scss';
import useMessage from 'antd/es/message/useMessage';
import { useEffect } from 'react';

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [messageApi, contextHolder] = useMessage();

  useEffect(() => {
    if (location.state && (location.state as {[x: string]: string}).content) {
      messageApi.open({
        type: 'error',
        content: (location.state as {[x: string]: string}).content,
        duration: 3,
      });   
    }
  }, [location.state]);
  
  return (
    <div className={styles.root}>
      {contextHolder}
      <div className={styles.gridForm}>
        <LoginForm />
        <div className={styles.close}>
          <button
            type="button"
            className={styles.btn}
            onClick={() => navigate(RoutePath.MAIN)}
          >
            <img className={styles.imgClose} src={Close} alt="close" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
