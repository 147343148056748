import { ReactElement, useEffect, useLayoutEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import Title from 'antd/es/typography/Title';

import {
  ISpecialistListFilters,
  ISpecialistListFiltersQuery,
} from '1_shared/config/interfaces/ISpecialistListFilters';
import {
  Breadcrumbs,
  Content,
  Faq,
  Feedback,
  Footer,
  MeetingsSingle2,
  Page,
  Statistics,
  SubscribeBlock,
  Typography,
} from '1_shared/ui';
import { Header, SpecialistCard } from '4_widgets';
import { SpecialistFilters } from '4_widgets/SpecialistFilters';

import useGetDictionaries from '../../../1_shared/api/dictionary/getDictionaries';
import useChangeLocalSubscribeStatus from '../../../1_shared/api/hooks/useChangeLocalSubscribeStatus';
import { useSubscriptions } from '../../../1_shared/api/hooks/useNotificationSubcribe';
import { RoutePath } from '../../../1_shared/config/routes';
import HelpChoose from '../../../4_widgets/HelpChoose/ui/HelpChoose';
import { ILocationSpec } from '../model/service/interfaces/ILocationSpec';
import useGetSpecialistList, {
  defaultPagination,
} from '../model/service/useGetSpecialistList';

import styles from './SpecialistsPage.module.scss';

const breadCrumbs = [
  {
    title: (
      <Link to={RoutePath.MAIN}>
        <Typography type="description">DOTHERAPY</Typography>
      </Link>
    ),
  },
  {
    title: (
      <Link to={RoutePath.SPECIALISTS}>
        <Typography type="description">СПЕЦИАЛИСТЫ</Typography>
      </Link>
    ),
  },
];

const SpecialistsPage = (): ReactElement => {
  const location = useLocation();
  const state = location.state as ILocationSpec;
  const { dictionary } = useGetDictionaries();
  const { getSubscribes } = useSubscriptions();
  const { changeSubscriptionsInUserObject } = useChangeLocalSubscribeStatus();
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const methods = useFormContext<ISpecialistListFilters>();

  const { specList, setFilters, viewMore, isLoading, setPagination } =
    useGetSpecialistList();

  const handleFiltersChange = (filters: ISpecialistListFiltersQuery) => {
    setPagination(defaultPagination);
    setFilters(filters.filter);
  };

  const scrollHandler = (e: any) => {
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
      9000
    ) {
      viewMore();
    }
  };

  /** При каждом открытии страницы каталога запрашиваем список подписок пользователя
   * Связано это с тем, что есть сервис авто-отписки в случае если пользователь был уведомлен о появлении нового слота у специалиста */
  useEffect(() => {
    if (user?.userId)
      (async () => {
        changeSubscriptionsInUserObject(await getSubscribes(user?.userId));
      })();
  }, []);

  useEffect(() => {
    if (state?.filters) {
      methods.reset(state.filters);
      setFilters(state.filters);
      location.state = {};
      window.history.replaceState({}, '');
    }
  }, [methods, setFilters, state?.filters]);

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (!isLoading) window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 100);
  }, []);

  return (
    <Page withFloatButton>
      <Header />
      <Content wrapperClass={styles.content}>
        <Breadcrumbs items={breadCrumbs} />
        <div className={styles.headerText}>
          <Typography type="h2">Вам помогут наши лучшие специалисты</Typography>
        </div>
        <div>
          <SpecialistFilters onChange={handleFiltersChange} />
          {specList?.map(spec => (
            <SpecialistCard
              spec={spec}
              dictionary={dictionary}
              key={spec?.id}
              showBriefAboutYourself
              showDuration={false}
            />
          ))}
          {!specList?.length && !isLoading && (
            <div className={styles.specsNotFound}>
              <Typography type="h3">Специалисты не найдены</Typography>
              <Typography>Попробуйте изменить условия поиска</Typography>
            </div>
          )}
          {isLoading && (
            <div className={styles.loader}>
              <Spin size="large" />
              <Title level={4}>Идет загрузка специалистов</Title>
            </div>
          )}
          <HelpChoose onChange={handleFiltersChange} />
        </div>
      </Content>
      <SubscribeBlock />
      <MeetingsSingle2 />
      <Feedback />
      <Statistics />
      <Faq />
      <Footer />
    </Page>
  );
};

export default SpecialistsPage;
